import { Dropdown, Navbar, Avatar } from 'flowbite-react';
import { FiShoppingCart, FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <Navbar  fluid rounded className='shadow pt-4 pb-4'>
      <Navbar.Brand>
        <Navbar.Toggle />
        <Link to='/' className='flex items-center'>
          <img
            alt='hh React Logo'
            className='mr-3 h-7'
            src='https://flowbite.com/docs/images/logo.svg'
          />
          <span className='self-center whitespace-nowrap hidden md:text-xl md:block font-semibold dark:text-white'>
            Handmade Happiness
          </span>
        </Link>
      </Navbar.Brand>
      <div className='flex md:order-2 items-center  gap-3'>
        <span className='text-xl text-black cursor-pointer'>
          <FiSearch />
        </span>
        <span className='text-xl text-black cursor-pointer'>
          <FiShoppingCart />
        </span>
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <Avatar
              size='xs'
              alt='User settings'
              img='https://flowbite.com/docs/images/people/profile-picture-5.jpg'
              rounded
            />
          }
        >
          <Dropdown.Header>
            <span className='block text-sm'>Bonnie Green</span>
            <span className='block truncate text-sm font-medium'>
              name@flowbite.com
            </span>
          </Dropdown.Header>
          <Dropdown.Item>Dashboard</Dropdown.Item>
          <Dropdown.Item>Settings</Dropdown.Item>
          <Dropdown.Item>Earnings</Dropdown.Item>
          <Dropdown.Divider />
          <Link to='logIn'>
            <Dropdown.Item>Login</Dropdown.Item>
          </Link>
        </Dropdown>
      </div>
      <Navbar.Collapse>
        <Navbar.Link className='text-base text-indigo-600' href='/'>
          <p>Home</p>
        </Navbar.Link>
        <Navbar.Link className='text-base' href='/about'>
          About
        </Navbar.Link>
        <Navbar.Link className='text-base' href='#'>
          Services
        </Navbar.Link>
        <Navbar.Link className='text-base' href='#'>
          Pricing
        </Navbar.Link>
        <Navbar.Link className='text-base' href='#'>
          Contact
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}

import './App.css';
import Header from './components/Header/Header';
import { Home } from './pages/Home/Home';
import FooterComponent from './components/Footer/Footer';
import Notification from './components/Notification/Notification';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import ProductOverView from './components/ProductOverView/ProductOverView';
import About from './pages/About/About';

function App() {
  return (
    <div className='App'>
      <Notification />
      <Header />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/productOverView' element={<ProductOverView />} />
        <Route exact path='/logIn' element={<Login />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <FooterComponent />
    </div>
  );
}

export default App;

import { FiBriefcase } from 'react-icons/fi';
import { Timeline } from 'flowbite-react';

export default function Steps() {
  return (
    <Timeline horizontal >
      <Timeline.Item>
        <Timeline.Point icon={FiBriefcase} />
        <Timeline.Content className='pt-3'>
          <Timeline.Title className='text-secondaryTextColor'>Flowbite Library v1.0.0</Timeline.Title>
          <Timeline.Body className='text-secondaryTextColor'>
            Get started with dozens of web components and interactive elements.
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
      <Timeline.Item>
        <Timeline.Point icon={FiBriefcase} />
        <Timeline.Content className='pt-3'>
          <Timeline.Title className='text-secondaryTextColor'>Flowbite Library v1.0.0</Timeline.Title>
          <Timeline.Body className='text-secondaryTextColor'>
            Get started with dozens of web components and interactive elements.
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
      <Timeline.Item>
        <Timeline.Point icon={FiBriefcase} />
        <Timeline.Content className='pt-3'>
          <Timeline.Title className='text-secondaryTextColor'>Flowbite Library v1.0.0</Timeline.Title>
          <Timeline.Body className='text-secondaryTextColor'>
            Get started with dozens of web components and interactive elements.
          </Timeline.Body>
        </Timeline.Content>
      </Timeline.Item>
    </Timeline>
  );
}

import React from 'react';
import './Home.css';
import Banner from '../../components/Banner/Banner';
import Steps from '../../components/Steps/Steps';
import Products from '../../components/Products/Products';
import Promos from '../../components/Promos/Promos';
import Reviews from '../../components/Reviews/Reviews';
import Trusted from '../../components/Trusted/Trusted';

export const Home = () => {
  return (
    <div>
      <div className='w-full'>
        <div >
          <Banner />
        </div>
      </div>
      <div className='mt-20 px-10 py-20 bg-indigo-600'>
        <p className='text-2xl  mb-10 font-bold text-center text-secondaryTextColor'>
          How It Works?
        </p>
        <Steps />
      </div>
      <div className='mt-10 py-10'>
        <p className='text-2xl   font-bold text-center text-indigo-600'>
          Build Something
        </p>
        <p className='text-base text-center font-semibold text-black w-3/5 py-3 m-auto'>
          Send something for baby, mom, or the whole family with these curated
          gifts. We do the heavy lifting, so you don’t have to! Pick your box,
          add a note, and BOXFOX will do the rest!
        </p>
        <Products />
      </div>
      <div className='mt-10 py-5'>
        <Promos />
      </div>
      <div className='mt-10'>
        <Trusted />
      </div>
      <div className='mt-10 py-5'>
        <Reviews />
      </div>
    </div>
  );
};
